<template>
  <div
    class="content-container print d-none"
    style="overflow: hidden;"
    id="month_print"      
  >
    <div>
      <table style="width: 100%">
        <tr >
          <td style="text-align: center;" colspan="4">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 10px;
                font-weight: bold;
              "
            >
              Marvel Trucking Solutions Inc
            </div>
            <!-- <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 8px;
              "
            >
              Mercedes Business Park, Mercedes Ave, Rizal
            </div>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 8px;
              "
            >
              BIR-TIN: 009-788-189-000
            </div> -->
          </td>
          <td></td>

          <!-- <td style="text-align: right">
            <img
              src="http://marveltrucking.com/wp-content/uploads/2019/03/marvellogo.png"
              style="height: 50px; width: 60%"
            />
          </td> -->
        </tr>
        <tr>
          <td style="text-align: center;" colspan="4">
            <span
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: bold;
              "
            >
              <span style="font-size: 10px">PAYSLIP &nbsp;</span>
            </span>
          </td>
          <tr>
          <td colspan="4" style="text-align: center">
            <span
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: bold;
                font-style: italic;
              "
            >
              <span style="font-size: 9px"
                >Pay Period :{{moment(dataList.payroll_date_from).format('MMMM DD, YYYY')}} - {{moment(dataList.payroll_date_to).format('MMMM DD, YYYY')}}
              </span
              >
            </span>
          </td>
        </tr>
        </tr>
       
      </table>
<hr>
      <table style="width: 100%; font-size: 11px">
        <tr>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
              
              "
            >
              <span style="font-size: 10px">                
                Name: <b>{{dataList.name}}</b></span
              >
            </div>
          </td>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                
              "
            >
              <span style="font-size: 10px">
               Employee ID: <b> </b></span
              >
            </div>
          </td>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                
              "
            >
              <span style="font-size: 10px">
                Daily Rate: <b>{{dataList.daily_rate | number_format('currency')}}</b></span
              >
            </div>
          </td>
          
        </tr>
        <tr>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                
              "
            >
              <span style="font-size: 10px">
               Position: <b>{{dataList.position}}</b></span
              >
            </div>
          </td>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                
              "
            >
              <span style="font-size: 10px">
                Area: <b>{{dataList.area}}</b></span
              >
            </div>
          </td>
          <td>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
              "
            >
              Date:______________________
            </div>
          </td>
         
        </tr>
      
        <tr>
          <td>
           <span style="font-size: 10px">
              <b>EARNINGS</b></span
          >
          </td>   
         
          <td>
           <span style="font-size: 10px">
              <b>DEDUCTIONS</b></span
          >
          </td>   
        </tr>  
      
        <tr>
          <td
            style="
              vertical-align: top;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;"
             >
            <table
              style="
                width: 100%;
                font-size: 11px;
                margin-top: 0px;">
              <thead >   
              <tr >
                <td style="
                    width: 10%;
                    
                    text-align: left;
                    font-weight: bold; ">
                  Earnings
                </td>
                <td
                  style="
                    width: 10%;
                    
                    text-align: left;
                    font-weight: bold;">
                  Trips/Days/Hrs
                </td>
                <td
                  style="
                    width: 10%;
                    
                    text-align: left;
                    font-weight: bold;">
                  Amount
                </td>
              </tr>
              </thead>
              <tbody>
              <!-- EARNINGS DATA -->
                <tr style="border: 1px solid black; height: 15px">
                  <td>
                    <small>Basic Pay </small>
                  </td>
                  <td>
                    <small> {{dataList.basic_salary_qty }} </small>
                  </td>
                  <td>
                    <small> {{dataList.basic_salary_amount | number_format(null,2)}}</small>
                  </td>
                </tr>
                <tr style="border: 1px solid black; height: 15px">
                  <td>
                    <small>Holiday Pay</small>
                  </td>
                  <td>
                    <small>{{dataList.holiday_qty ? dataList.holiday_qty : '-' }}</small>
                  </td>
                  <td>
                    <small> {{dataList.holiday_qty_amount ? dataList.holiday_qty_amount : '-' | number_format(null,2)}}</small>
                  </td>
                </tr>
                <tr style="border: 1px solid black; height: 15px">
                  <td>
                    <small>Overtime </small>
                  </td>
                  <td>
                    <small>-</small>
                  </td>
                  <td>
                    <small> {{dataList.overtime ? dataList.overtime : '-' | number_format(null,2)}}</small>
                  </td>
                </tr>
                <tr style="border: 1px solid black; height: 15px">
                  <td>
                    <small>Allowance </small>
                  </td>
                  <td>
                    <small>-</small>
                  </td>
                  <td>
                    <small> {{dataList.allowance ? dataList.allowance : '-' | number_format(null,2)}}</small>
                  </td>
                </tr>
                <tr style="border: 1px solid black; height: 15px">
                  <td>
                    <small>Adjustment </small>
                  </td>
                  <td>
                    <small>-</small>
                  </td>
                  <td>
                    <small> {{dataList.adjustment ? dataList.adjustment : '-' | number_format(null,2)}}</small>
                  </td>
                </tr>
                
              </tbody>
             
            </table>
            <div style="margin-top:10px">
              <div><span>Total Earnings : {{dataList.total_earnings | number_format('currency')}}</span></div>
              <div><span>Total Deduction : {{dataList.total_deductions | number_format('currency')}}</span></div>
              <div><span>NET PAY : {{dataList.net_pay | number_format('currency')}}</span></div>
            </div>
            <div style="margin-top:35px">
              <table style="font-size: 10px;">
                <tr>
                  <td>
                    <div style="vertical-align: text-top;">
                      <div
                        style="
                          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                          margin-bottom: -3px;
                          padding-left: 10px;
                        "
                      >
                      <small>{{dataList.name}}</small>
                      </div>
                      <div
                        style="
                          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                          margin-bottom: -10px;
                        "
                      >
                        ________________________
                      </div>
                      <br />
                      <div
                        style="
                          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                          padding-bottom: 7px;
                        "
                      >
                        Employee's Signature
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="vertical-align: text-top; padding-left: 7px;">
                      <div
                        style="
                          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                          margin-bottom: -3px;
                          padding-left: 10px;
                        "
                      >
                      <small>&nbsp;</small>
                      </div>
                <div
                  style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    margin-bottom: -10px;
                  "
                >
                  ________________________
                </div>
                <br />
                <div
                  style="
                    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                    padding-bottom: 7px;
                  "
                >
                  Prepared By
                </div>
              </div>
                  </td>
                </tr>
              </table>
            </div>

          </td>
        <!-- DEDUCTIONS -->
          
          <td
            style="
              padding-right: 7px;
              vertical-align: top;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;"
              colspan="2"
              >
            <table
              style="
                
                width: 100%;
                font-size: 11px;
                margin-top: 0px;">
              <!-- DEDUCTIONS DATA -->
            <tbody>
              <tr style="border: 1px solid black; height: 15px">
                <td style="
                    ">
                  <small>Rider Late </small>
                </td>
               
                <td style="" > 
                  <small> {{dataList.rider_late ? dataList.rider_late : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style="border: 1px solid black; height: 15px">
                <td
                  style="
                    ">
                  <small>Charges</small>
                </td>
               
                <td style="" >
                  <small> {{dataList.charges ? dataList.charges : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style="">
                <td
                  style="
                    ">
                  <small>Meals</small>
                </td>
               
                <td style="" >
                  <small> {{dataList.meals ? dataList.meals : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style="">
                <td
                  style="
                    ">
                  <small>SSS </small>
                </td>
               
                <td style="" >
                  <small> {{dataList.sss ? dataList.sss : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style=" height: 15px">
                <td
                  style="
                    ">
                  <small>SSS Loan </small>
                </td>
               
                <td style=" " >
                  <small> {{dataList.sss_loan ? dataList.sss_loan : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style=" height: 15px">
                <td
                  style="
                    ">
                  <small>Pag-ibig </small>
                </td>
               
                <td style=" " >
                    <small> {{dataList.pag_ibig ? dataList.pag_ibig : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style=" height: 15px">
                <td
                  style="
                    ">
                  <small>Pag-ibig STL </small>
                </td>
               
                <td style=" " >
                  <small> {{dataList.pag_ibig_stl ? dataList.pag_ibig_stl : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style=" height: 15px">
                <td style="
                    ">
                  <small>PHIC </small>
                </td>
               
                <td style="" >
                  <small> {{dataList.phic ? dataList.phic : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style=" height: 15px">
                <td
                  style="
                    ">
                  <small>Utilities </small>
                </td>
               
                <td style=" " >
                  <small> {{dataList.utilities ? dataList.utilities : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style="height: 15px">
                <td
                  style="
                    ">
                  <small>Cash Advance </small>
                </td>
               
                <td style="" >
                  <small> {{dataList.cash_advance ? dataList.cash_advance : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              <tr style="height: 15px">
                <td
                  style="
                    ">
                  <small>Other Deductions </small>
                </td>
               
                <td style="" >
                  <small> {{dataList.other_deductions ? dataList.other_deductions : '-' | number_format(null,2)}}</small>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
      </tr>
      
    </table>

      <hr style="border-top: 1px dashed;">
    </div>
  </div>
</template>

<style scoped>
@page {
  size: auto;
  margin: 0mm;
}
.test {
  color: blue;
}
@media print {
  .col-special-1,
  .col-special-2,
  .col-special-3,
  .col-special-4,
  .col-special-5,
  .col-special-6,
  .col-special-7,
  .col-special-8,
  .col-special-9,
  .col-special-10,
  .col-special-11,
  .col-special-12 {
    float: left;
  }

  .col-special-12 {
    width: 100%;
  }

  .col-special-11 {
    width: 91.66666666666666%;
  }

  .col-special-10 {
    width: 83.33333333333334%;
  }

  .col-special-9 {
    width: 75%;
  }

  .col-special-8 {
    width: 66.66666666666666%;
  }

  .col-special-7 {
    width: 58.333333333333336%;
  }

  .col-special-6 {
    width: 50%;
  }

  .col-special-5 {
    width: 41.66666666666667%;
  }

  .col-special-4 {
    width: 33.33333333333333%;
  }

  .col-special-3 {
    width: 25%;
  }

  .col-special-2 {
    width: 16.666666666666664%;
  }

  .col-special-1 {
    width: 8.333333333333332%;
  }
}
</style>

<script>
import moment from "moment";
export default {
  mounted() {},
  props: {
    details: {
      booking_no: "",
      equipment: "",
      vehicle_type_id: "",
      maker_model_id: "",
      origin_id: "",
      vendor_id: "",
      driver_id: "",
      body_no: "",
      year: "",
      with_gps: "",
      company_owned: true,
      plate_no: "",
      registration_date: "",
      asset_no: "",
      engine_no: "",
      engine_size: "",
      chassis_no: "",
      transmission_type: "",
      transfer_date: "",
      color: "",
      fuel_type: "",
      later_renewal_date: "",
      acquisition_date: "",
      acquisition_price: "",
      remarks: "",
      vehicle_photo: "",
      or_photo: "",
      cr_photo: "",
      insurance_photo: "",
      status: "active",
      location: "",
    },
    dataList: [],
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
