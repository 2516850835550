<template>
	<div>
		<CCard class="p-2"> 
			<CCardHeader>
				<CRow >
					<CCol lg="6">
						<h4>Payroll for {{dataList.name}}</h4>
					</CCol> 
					<CCol lg="6">
			      		<div style="text-align: right">  
							
							<CButton v-if="config.getPermission('payroll').print"  class="mr-1" color="btn btn-info" size="sm" @click="print"><i class="fa fa-print"></i> Print Payslip</CButton> 
			      			<!-- <CButton class="mr-2" color="btn btn-outline-danger" size="sm" @click="backToTable()"><i class="fa fa-close"></i> Close</CButton> -->
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
	<CCardBody>
				
		<CRow class="mr-5 ml-5 pl-4 pr-4"> 
						<CCol lg="4">
							<h6><strong>
                                Name
                            </strong></h6>
							<h6>{{dataList.name}}</h6> 
                        
						</CCol> 
						<CCol lg="4">
							<h6><strong>
                               Area
                            </strong></h6>
                            <h6> {{dataList.area}}
                            </h6>
						</CCol> 
                        <!-- <CCol lg="4">
							<h6><strong>
                               Employee ID
                            </strong></h6>
                            <h6> 9,000.00
                            </h6>
						</CCol>  -->
                        <CCol lg="4">
							<h6><strong>
                                Position
                            </strong></h6>
                            <h6> {{dataList.position}}
                            </h6>
						</CCol> 
						
						<CCol lg="4">
							<h6><strong>
                               Payment Method
                            </strong></h6>
                            <h6> {{dataList.payment_method}}
                            </h6>
						</CCol> 
						<CCol lg="4">
							<h6><strong>
								Pay Period 
                            </strong></h6>
                            <h6> 
								{{moment(dataList.payroll_date_from).format('MMMM DD, YYYY')}} - {{moment(dataList.payroll_date_to).format('MMMM DD, YYYY')}}
                            </h6>
						</CCol> 
						
							
		</CRow>
		<br>
		<br>
		<CRow class="mr-5 ml-5 pl-4 pr-4"> 
			<CCol lg="6">
				<h5 style="color: #1352a1;">EARNINGS</h5>
			</CCol>
			<CCol lg="6">
				<h5 style="color: #1352a1;">DEDUCTIONS</h5>
			</CCol>
		</CRow> 
		<CRow class="mr-5 ml-5 pl-4 pr-4"> 
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-bordered table-sm text-left">
						<thead class="bg-light " style="font-weight:bold">
							<tr>
								<td colspan="6">Earnings</td>
								<td colspan="3">Trips/Days/Hrs</td>
								<td colspan="3">Amount</td>
								
							</tr>
						</thead>
						<tbody>
							<tr >
								<td colspan="6">Basic Salary</td>
								<td colspan="3">{{dataList.basic_salary_qty}}</td>
								<td colspan="3">{{(dataList.basic_salary_amount ? dataList.basic_salary_amount : '-') | number_format(null,2) }}</td>
								
							</tr>

							<tr >
								<td colspan="6">Holiday Pay</td>
								<td colspan="3">{{dataList.holiday_qty}}</td>
								<td colspan="3">{{(dataList.holiday_qty_amount ? dataList.holiday_qty_amount : '-') | number_format(null,2)}}</td>
								
							</tr>
							<tr >
								<td colspan="6">Overtime</td>
								<td colspan="3">-</td>
								<td colspan="3">{{(dataList.overtime ? dataList.overtime :'-') | number_format(null,2) }}</td>
								
							</tr>
							<tr >
								<td colspan="6">Allowance</td>
								<td colspan="3">-</td>
								<td colspan="3">{{(dataList.allowance ? dataList.allowance :'-') | number_format(null,2) }}</td>
								
							</tr>
							<tr >
								<td colspan="6">Adjustment</td>
								<td colspan="3">-</td>
								<td colspan="3">{{(dataList.adjustment  ? dataList.adjustment : '-') | number_format(null,2)}}</td>
								
							</tr>
							
						</tbody>	
					</table>

				</div>  

					<CRow >
						<CCol lg="8">
							<strong style="font-size:15px"> Total Earnings</strong>
						</CCol>
						<CCol lg="4" class="text-left">
							<strong style="font-size:15px" >{{dataList.total_earnings  | number_format('currency')}}</strong>
						</CCol>
					</CRow>   
					<CRow >
						<CCol lg="8">
							<strong style="font-size:15px"> Total Deductions</strong>
						</CCol>
						<CCol lg="4" class="text-left">
							<strong style="font-size:15px">{{dataList.total_deductions | number_format('currency')}}</strong>
						</CCol>
					</CRow>        
					<CRow class="pt-0 mb-2 bg-secondary text-dark">
						<CCol lg="8">
							<strong style="font-size:18px"> NET PAY</strong>
						</CCol>
						<CCol lg="4" class="text-left">
							<strong style="font-size:18px">{{dataList.net_pay  | number_format('currency')}}</strong>
						</CCol>
					</CRow>
                                
                      
            </CCol>
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-bordered table-sm text-left">
						<thead class="bg-light " style="font-weight:bold">
							<tr>
								<td colspan="9">Deductions</td>
								<td colspan="3">Amount</td>
								
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="9">Rider Late</td>
								<td colspan="3">{{(dataList.rider_late ? dataList.rider_late : '-') | number_format(null,2)}}</td>
							</tr>

							<tr>
								<td colspan="9">Charges</td>
								<td colspan="3">{{(dataList.charges ? dataList.charges : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">Meals</td>
								<td colspan="3">{{(dataList.meals ? dataList.meals : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr>
								<td colspan="9">SSS</td>
								<td colspan="3">{{(dataList.sss ? dataList.sss : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">SSS Loan</td>
								<td colspan="3">{{(dataList.sss_loan ? dataList.sss_loan : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">PHIC</td>
								<td colspan="3">{{(dataList.phic ? dataList.phic : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr>
								<td colspan="9">Pag-ibig</td>
								<td colspan="3">{{(dataList.pag_ibig ? dataList.pag_ibig : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr>
								<td colspan="9">Pag-ibig STL</td>
								<td colspan="3">{{(dataList.pag_ibig_stl ? dataList.pag_ibig_stl : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">Utilities</td>
								<td colspan="3">{{(dataList.utilities ? dataList.utilities : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">Cash Advance</td>
								<td colspan="3">{{(dataList.cash_advance ? dataList.cash_advance : '-') | number_format(null,2)}}</td>
								
								
							</tr>
							<tr >
								<td colspan="9">Other Deductions</td>
								<td colspan="3">{{(dataList.other_deductions ? dataList.other_deductions : '-') | number_format(null,2)}}</td>                                                                                       
							</tr>               
						</tbody>
					</table>
				</div>  
                                
                      
            </CCol>

			
						
		</CRow> 
	
				
	</CCardBody> 
			  
</CCard>  

<!-- <Print :details="dataParams" :dataList="dataList"/> -->
<Print :details="dataParams" :dataList="this.dataList" />
		
		
	</div>
</template>
<script> 

import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import Loader from '../../../loader.mixin.js';
import '../../style.css';
import moment from 'moment';
import Print from './print_personnel';
import { json } from 'body-parser';
export default {
	mounted(){  
		this.getData(); 				
	},
	data(){
		return{
			displayedBooking: [],
			currentPage: 1,
			dataParams: {
			
				remarks: "",				
			},			
			dataList: {},
			isLoading: false,
			config,
			moment,
			limit: 20
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect, Print},
	methods: {  
		// updatePage(page) {
		// 	let t = [...this.displayedBookingHolder];
		// 	let index = (page - 1) * this.limit;
		// 	this.displayedBooking = t.slice(index, index + this.limit);
		// },		
		reload(){
			setTimeout(()=>{
				this.getData();
			},500) 
		},
		
		getData(){
			this.$showLoading(true)
			axios.get(`${config.api_path}/payroll/personnel/${this.$route.params.payroll_id}/${this.$route.params.personnel_id}`)
			.then((response) => {
				this.$showLoading(false)
				const {data} = response.data;
				this.dataList = {...data};       
				console.log(this.dataList);
			}).catch(err => {
				this.$showLoading(false)
			});      
			},
		 print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">')
		    mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">')
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('month_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },

	   

	   
		
 	}
	
}
</script>
